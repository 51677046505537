import { useCallback, useRef, useState, forwardRef } from "react";
import { TextField, Button, Stack } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Webcam from "react-webcam";
import axios from 'axios';
import constants from "../constants";
import { useWindowDimensions } from "../general/userFunctions";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  visitId: number;
};

const CustomWebcam: React.FC<Props> = ({visitId}) => {
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null); // [1
  const [label, setLabel] = useState<string | null>(null); // [1
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const { height, width } = useWindowDimensions();

  
  const API_URL = constants.API_URL;
  
  const capture = useCallback(() => {
    if(webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }
  }, [webcamRef]);

  const handleLabelChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setLabel(event.target.value);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSaveAlert(false);
  };

  const savePhoto = async () => {
      if(imgSrc) {

          const response = await axios.post(`${API_URL}/saveimg`,{ visitId, label, imgSrc});
          console.log(response);
          setShowSaveAlert(true);
      }
  };   


  return (

    <div className="container">
    {imgSrc ?(
        <img src={imgSrc} alt="webcam"/>
    ): (
        <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={{
                width: width,
                height: height,
                facingMode: "environment"
              }}

            screenshotFormat="image/png"/>
    )}
    <div className="btn-container">
        {imgSrc ? (<>
            <Stack spacing={4} direction="column" alignItems="center" justifyContent="space-around">    
            <TextField
                id="label"
                label="Lýsing"
                fullWidth
                onChange={handleLabelChange}
            />
            <Stack spacing={4} direction="row">
            <Button variant="contained" startIcon={<SaveIcon/>} onClick={savePhoto}>Vista mynd</Button>
            <Button variant="contained" startIcon={<CameraAltIcon/>} onClick={() => setImgSrc(null)}>Taka nýja mynd</Button>
            <Button variant="contained" startIcon={<CloseIcon/>} onClick={() => window.close()}>Hætta</Button>            
            </Stack>
          </Stack>
          <Snackbar open={showSaveAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
              <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                Mynd vistuð
              </Alert>
          </Snackbar>             
            </>
        ): (
          <Stack direction="column" alignItems="center" justifyContent="space-around">
          <Button variant="contained"startIcon={<CameraAltIcon/>} onClick={capture}>Taka mynd</Button>
          <Button variant="contained" startIcon={<CloseIcon/>} onClick={() => window.close()}>Hætta</Button>            
          </Stack>
        )}  
        </div>
    </div>
  );
}  
export default CustomWebcam;