import CustomWebcam from './components/CustomWebcam';
import { Stack, Typography } from '@mui/material';

function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  const visit = queryParameters.get("v")
  const name = queryParameters.get("n")

  return (
    <div className="App">
      <Stack spacing={4} direction="row" alignItems="center" justifyContent="space-between" sx={{bgcolor: '#5e8ba0', color: 'white', padding: '15px'}}>
        <img src="logo_white.png" alt="logo" height={50} width={50}></img>
        <Typography variant="h6" >{name}</Typography>
       </Stack> 
       {visit ? <CustomWebcam visitId={parseInt(visit as string)}/> :
       <Stack alignItems="center" justifyContent="center">
          <Typography variant="h2" sx={{padding: '15px'}}>Myndavél ekki tengd</Typography>
          </Stack>  
          }


    </div>
  );
}

export default App;


